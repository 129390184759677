/*!
*   Integrated Labor
*
*   Version: 1.0.0
*   Author: Arise Design + Brand
*   Email: developer@arisedesigngroup.com
*/

@charset 'utf-8';

// Vendor
@import 'vendor/__vendor-dir';

// Abstracts
@import 'abstracts/__abstracts-dir';

// Base Styling
@import 'base/__base-dir';

// Components
@import 'components/__components-dir';

// Layout
@import 'layouts/__layouts-dir';
