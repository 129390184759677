/*
*   Button
*
*   Global button styles
*/

.btn {

  display: inline-block;
  text-decoration: none;
  border-radius: $button-border-radius;

  /*
  *   Overall Styles
  */

  &.btn--solid {  // Solid, flat button
    color: $button-solid-text-color;
    &.btn--green { background: $color-green; }
  }

  &.btn--ghost { // Ghost button
    color: $button-ghost-text-color;
    background: transparent;
    border: $button-ghost-border-width solid $button-ghost-border-color;
  }

  /*
  *   Sizes
  */
  &.btn--std {  // Standard, medium-sized button
    font-size: $body-font-size-small;
    font-weight: $button-medium-font-weight;
    @include padding( $button-medium-vertical-padding $button-medium-horizontal-padding );
  }

  &.btn--lg { // Large-sized button
    font-size: $body-font-size-med;
    font-weight: $button-large-font-weight;
    @include padding( $button-large-vertical-padding $button-large-horizontal-padding );
  }


}
