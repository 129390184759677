/*
*   Use Case Grid
*
*   Styles for a grid section of industry case studies.
*/

.use-case-grid {

  .use-case-grid__header {
    text-align: center;

    .use-case-grid__headline {
      @extend .inner-heading-1;
      margin-bottom: $use-case-grid-header-margin;
    }
  }

  .use-case-grid__grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .use-case-grid__footer {
    text-align: center;
    margin-top: $use-case-grid-footer-margin;
  }

}

.ucg-item {
  width: 45%;

  &:not(:first-child), &:not(:nth-child(2)) {
    margin-top: 4rem;
  }

  .ucg-item__image {
    position: relative;
    padding-top: $use-case-grid-image-padding;
    margin-bottom: $use-case-grid-image-margin;

    img {
      position: absolute;
      top: 0; left: 0;
      width: 100%; height: 100%;
      object-fit: cover;
    }
  }

  .ucg-item__title {
    @extend .inner-heading-2;
    color: $use-case-grid-title-color;
    margin-bottom: $use-case-grid-title-margin;
  }

  .ucg-item__caption {
    margin-bottom: $use-case-grid-caption-margin;
    color: $use-case-grid-caption-color;
  }

}
