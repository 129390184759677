/*
*   Section
*
*   The global page section styles.
*/

.section {

  @include padding( $base-section-padding null );

}
