/*
*   Two Column
*
*   Styles for a section with rich text on both sides.
*/

.two-column {

  .container { display: flex; }

  .two-column__column {
    flex: 1;
    @include margin(null ($gutter * 2));

    &:first-child { margin-left: 0; }
    &:last-child { margin-right: 0; }
  }



}
