/*
*   CTA
*
*   Simple CTA styles
*/

.cta {

  background: $cta-background;

  .container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .cta__inner {
    text-align: center;
    max-width: $cta-inner-max-width;
  }

  .cta__text {
    @extend .inner-heading-2;
    font-weight: $cta-text-font-weight;
    line-height: $cta-text-line-height;
  }

}
