/*
*   Split
*
*   Styles for a section with an image on one side, text on the other.
*/

.split {

  // Turn the container flex.
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  // Flip the flex if the image is on the opposite side.
  &.split--right {
    .container {
      flex-direction: row-reverse;
    }

    .split__column {
      img {
        margin-left: auto;
      }
    }
  }

  // Common styles for both sides.
  .split__column {
    flex: 1;
    @include margin( null $gutter );

    img { max-width: 100%; }  // No images bleeding past column edge.
  }

  // Content-side styles
  .split__content {
    @include margin( null ($gutter * 4) );

    h2 { @extend .inner-heading-1; }
    h3 { @extend .inner-heading-2; }
    h4 { @extend .inner-heading-3; }
    h5 { @extend .inner-heading-4; }
  }

}
