/*
*   Drag
*
*   Extendable lobal class that makes images not able to be dragged.
*/

.not-draggable {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}
