/*
*   Full Width Split
*
*   Styles for the full-width version of the split section.
*/

.fw-split {

  position: relative;

  .container {
    display: flex;

    &.stretch {
      width: 100%;
      box-sizing: border-box;
      padding: 0 10%;
    }
  }

  .fw-split__image {
    position: absolute;
    top: 0;
    width: 45%;
    height: 100%;

    img {
      position: absolute;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .fw-split__body {
    width: 45%;
  }

  .fw-split__heading {
    @extend .inner-heading-1;
  }

  &.fw-split--right {
    .fw-split__image { left: 0; }
    .container { justify-content: flex-end; }
  }
  &.fw-split--left {
    .fw-split__image { right: 0; }
    .container { justify-content: flex-start; }
  }

  &.bg-dark-teal, &.bg-dark-grey {
    .fw-split__heading {
        @extend .inverse-heading;
    }
  }

  .fw-split__body {
    p, ul, li {
      color: $color-light-green;
    }

    .fw-split__footer .btn {
      margin-top: 1rem;
    }
  }

}
