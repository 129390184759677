/*
*   Body
*
*   Styling for the global <body> element.
*/

body {

  font-family: $body-font;
  font-size: $body-font-size-med;

}
