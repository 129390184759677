/*
*   Team
*
*   Styles for the team grid, team members, etc.
*/

.team {

  .team__heading { @extend .inner-heading-1; }

  .team__wrapper {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }


}

.team-member {
  width: $team-member-column;
  margin-top: $team-member-gap;

  .team-member__wrapper {
    max-width: $team-member-max-width;
  }

  &:nth-child( 3n ) {
    .team-member__wrapper {
      margin-left: auto;
    }
  }

  &:nth-child( 3n - 1 ) {
    .team-member__wrapper {
      margin-left: auto;
      margin-right: auto;
    }
  }

  .team-member__photo {
    padding-top: 100%;
    position: relative;
    background: $team-member-photo-mat;
    overflow: hidden;

    img {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
      transition: transform $team-member-hover-fx-speed $team-member-hover-fx-easing;
    }

    &:hover {
      img {
        transform: scale($team-member-hover-fx-scale);
        transform-origin: center;
      }
    }
  }

  .team-member__name {
    @extend .inner-heading-3;
    margin-top: $team-member-name-top-margin;
  }

  .team-member__role {
    font-size: $team-member-role-size;
    color: $team-member-role-color;
    margin-top: $team-member-role-top-margin;
  }

}
