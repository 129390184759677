/*
*   Clients
*
*   Styles for the split section with the client grid.
*/

.clients {

  .container { align-items: center; }

  .clients__grid {
    width: 80%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .client {

    width: 25%;
    position: relative;
    opacity: .75;

    &:not(:nth-child(1)), &:not(:nth-child(2)), &:not(:nth-child(3)) {
      margin-top: 2rem;
    }

    &:nth-child(3n-1) {
      margin-left: 12.5%;
      margin-right: 12.5%;
    }

    img {
      max-width: 100%;
      filter: grayscale(1);
      @extend .not-draggable;
    }

  }

}
