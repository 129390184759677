/*
*   Post Hero
*
*   Styles for the post hero block.
*/

.post-hero {
  @include padding( $post-hero-padding null );
  position: relative;
  background: $post-hero-background;

  .post-hero__image {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: $post-hero-image-opacity;

    img {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
      user-drag: none;
      user-select: none;
      -moz-user-select: none;
      -webkit-user-drag: none;
      -webkit-user-select: none;
      -ms-user-select: none;
    }
  }

  .post-hero__title {
    @extend .page-heading-1;
    @extend .inverse-heading;
    max-width: $post-hero-title-max-width;
    margin-bottom: $post-hero-title-bottom-margin;
  }

  .post-hero__meta { color: $post-hero-meta-color; }
  .post-hero__date { color: $post-hero-date-color; }

  // Breadcrumb
  .breadcrumb {
    font-size: $page-hero-breadcrumb-size;
    margin-bottom: $page-hero-breadcrumb-margin;
    color: $color-white;

    .breadcrumb__link a {
      color: $page-hero-breadcrumb-link-color;
    }

  }

  .container { position: relative; }
}
