/*
*   Color
*
*   Global styles to turn text and backgrounds various colors.
*/

.green {
  color: $color-green;
  opacity: 1;
}

.white {
  color: $color-white;
  opacity: 1;
}

.grey {
  color: $color-grey;
  opacity: 1;
}

.dark-teal {
  color: $color-dark-teal;
  opacity: 1;
}

.bg-green {
  background: $color-green;
}

.bg-white {
  background: $color-white;
}

.bg-light-grey {
  background: $color-light-grey;
}

.bg-grey {
  background: $color-grey;
}

.bg-dark-teal {
  background: $color-dark-teal;
}

.bg-dark-grey {
  background: $color-dark-grey;
}
