/*
*   Post Grid
*
*   Styles for the post grid on the Insights page.
*/

.post-grid {

  padding-top: 0;

  .post-grid__posts {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  .post-grid__sorry {
    border: $post-grid-sorry-border;
    width: 100%;
    padding: $post-grid-sorry-padding;
    color: $post-grid-sorry-text-color;
  }

  .post-grid__footer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: $post-grid-footer-gap;
  }

}

.post-block {
  width: $post-grid-block-width;
  text-decoration: none;

  &:nth-child(3n + 1) {
    padding-right: $post-grid-padding;
  }

  &:nth-child(3n) {
    padding-left: $post-grid-padding;
  }

  &:nth-child(3n - 1) {
    padding-left: $post-grid-padding / 2;
    padding-right: $post-grid-padding / 2;
  }

  .post-block__image {
    position: relative;
    padding-top: $post-grid-image-height;
    background: $post-grid-image-bg-color;
    margin-bottom: $post-grid-image-bottom-margin;
    border-radius: $post-grid-image-border-radius;

    img {
      position: absolute;
      top: 0; left: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .post-block__cat {
    color: $post-grid-category-color;
    margin-bottom: $post-grid-category-bottom-margin;
    font-size: $post-grid-category-font-size;
  }

  .post-block__title {
    @extend .inner-heading-2;
    line-height: $post-grid-title-line-height;
    margin-bottom: $post-grid-title-bottom-margin;
  }

  .post-block__caption {
    color: $post-grid-caption-color;
    margin-bottom: $post-grid-caption-bottom-margin;
  }

  .post-block__tags {
    .tag {
      margin-right: $post-grid-tag-spacing;
    }
  }

}
