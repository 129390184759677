/*
*   Typography
*
*   Global heading styles to be extended later.
*/

// Home Hero Heading, Product Page Heading
.page-heading-1 {
  font-size: $page-heading-1-size;
  color: $heading-color;
  line-height: $heading-line-height;
  font-weight: $heading-font-weight;
  font-family: $heading-font;
}

// Inner Page Headings, etc.
.page-heading-2 {
  font-size: $page-heading-2-size;
  color: $heading-color;
  line-height: $heading-line-height;
  font-weight: $heading-font-weight;
  font-family: $heading-font;
}

// On-Page Heading Tier 1
.inner-heading-1 {
  color: $heading-color;
  font-size: $inner-heading-1-size;
  line-height: $heading-line-height;
  font-weight: $heading-font-weight;
  font-family: $heading-font;
}

// On-Page Heading Tier 2
.inner-heading-2 {
  color: $heading-color;
  font-size: $inner-heading-2-size;
  line-height: $heading-line-height;
  font-weight: $heading-font-weight;
  font-family: $heading-font;
}

// On-Page Heading Tier 3
.inner-heading-3 {
  color: $heading-color;
  font-size: $inner-heading-3-size;
  line-height: $heading-line-height;
  font-weight: $heading-font-weight;
  font-family: $heading-font;
}

// On-Page Heading Tier 4
.inner-heading-4 {
  color: $heading-color;
  line-height: $heading-line-height;
  font-weight: $heading-font-weight;
  font-size: $inner-heading-4-size;
  font-family: $heading-font;
}

// For headings on dark backgrounds...
.inverse-heading {
  color: $heading-invert-color;
  line-height: $heading-line-height;
  font-weight: $heading-font-weight;
  font-family: $heading-font;
}
