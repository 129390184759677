/*
*   Footer
*
*   Page footer styles
*/

footer.page-footer {

  background: $page-footer-background;
  @include padding( $page-footer-vertical-padding null );
  font-size: $footer-font-size;

  a {
    text-decoration: none;
    color: $footer-link-color;
    line-height: $line-height-flat;
    @include margin(null null $footer-nav-link-spacing null);

    &:not(.btn) {
      opacity: $footer-link-opacity;
    }
  }

  /*
  *   Navigation Groups
  */

  .footer-group {

    display: flex;
    width: 100%;

    &:not(:first-child) {
      margin-top: $footer-group-spacing;
    }

    & > * {
      @include margin(null $gutter);
    }

    .footer-group__header {
      font-size: $footer-header-font-size;
      font-weight: $footer-header-font-weight;
      line-height: $footer-header-line-height;
      color: $footer-header-font-color;
      flex: 1;
    }

    .footer-group__cell {
      flex: 1;
    }

  }

  /*
  *   Navs
  */

  .footer-nav {

    a {
      display: block;
      text-decoration: none;
      color: $footer-link-color;
      opacity: $footer-link-opacity;
      line-height: $line-height-flat;
      float: left;
      clear: both;
      @include margin(null null $footer-nav-link-spacing null);

      &:last-of-type {
        margin-bottom: 0;
      }

      &.parent {  // Parent page link
        opacity: $footer-emphasized-text-opacity;
        font-size: $footer-emphasized-text-size;
        font-weight: $footer-emphasized-text-weight;
        @include border-color( null null $footer-emphasized-link-underline-color null );
        @include border-width( null null $footer-emphasized-link-underline-width null );
        @include border-style( null null solid null);
        @include margin(null null $footer-parent-link-bottom-margin null);

        & + .parent {
          margin-top: -$footer-parent-link-bottom-margin / 2;
        }
      }
    }

  }

}
