/*
*   Img Block
*
*   Full-width image block styles in various themes.
*/

.img-block {

    background: $img-block-background;
    width: 100%;

    &.img-block--basic {

      position: relative;
      padding-top: $img-block-height * 1.25;  // Correction for full-width.

      .img-block__img {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;

        img {
          height: 100%;
          width: 100%;
          position: absolute;
          object-fit: cover;
        }
      }

    }

    &.img-block--theater {

      .container {
        box-sizing: content-box;
        padding-top: $img-block-height;
        position: relative;
        border-left: $img-block-theater-theme-border solid $img-block-theater-theme-border-color;
        border-right: $img-block-theater-theme-border solid $img-block-theater-theme-border-color;
      }

      .img-block__img {

        position: absolute;
        height: 100%;
        width: $img-block-theater-img-width;
        top: 0;
        left: 0;
        border-right: $img-block-theater-theme-border solid $img-block-theater-theme-border-color;

        img {
          height: 100%;
          width: 100%;
          position: absolute;
          object-fit: cover;
        }

      }

    }

}
