/*
*   Variables
*
*   Global variables common across the entire website.
*/


/*
*   =-= Colors
*/
        $color-green:         #00C55E;
        $color-light-green:   #B2BFB8;
        $color-grey:          #697179;
        $color-dark-teal:     #143131;
        $color-dark-grey:     #383838;
        $color-light-grey:    #F3F6F5;
        $color-white:         #FFFFFF;

/*
*   =-= Typography
*/
        $font-family-display:  'ILS Display', Arial, Helvetica, sans-serif;
        $font-family-text:     'ILS Text', Arial, Helvetica, sans-serif;

        $body-font:            $font-family-text;
        $heading-font:         $font-family-display;

        $font-weight-hairline:      100;
        $font-weight-thin:          200;
        $font-weight-light:         300;
        $font-weight-normal:        400;
        $font-weight-medium:        500;
        $font-weight-semibold:      600;
        $font-weight-bold:          700;
        $font-weight-heavy:         800;
        $font-weight-black:         900;

        $line-height-flat:          1;
        $line-height-small:         1.25;
        $line-height-normal:        1.75;

        $body-font-size-small:      0.875rem;
        $body-font-size-med:        1.0625rem;

        $paragraph-margin:          1.5rem;
        $paragraph-line-height:     $line-height-normal;
        $paragraph-color:           $color-grey;

        $heading-color:             $color-dark-teal;
        $heading-invert-color:      $color-white;

        $page-heading-1-size:       4.25rem;
        $page-heading-2-size:       3.75rem;
        $inner-heading-1-size:      3.125rem;
        $inner-heading-2-size:      2rem;
        $inner-heading-3-size:      1.5rem;
        $inner-heading-4-size:      1.25rem;

        $heading-line-height:       $line-height-flat;
        $heading-font-weight:       $font-weight-semibold;


/*
*   =-= Layout
*/
        $gutter:                    1rem;
        $base-section-padding:      11.25rem;


/*
*   =-= Buttons
*/
        $button-border-radius:              2px;

        $button-solid-text-color:           $color-white;
        $button-ghost-text-color:           $color-dark-teal;

        $button-ghost-border-color:         #DBDFDE;
        $button-ghost-border-width:         1px;

        $button-medium-vertical-padding:    1.3125rem;
        $button-medium-horizontal-padding:  2.625rem;
        $button-medium-font-weight:         $font-weight-medium;

        $button-large-vertical-padding:     1.6875rem;
        $button-large-horizontal-padding:   3.25rem;
        $button-large-font-weight:          $font-weight-medium;

/*
*   =-= Navbar
*/
        $navbar-height:                     11.875rem;
        $nav-item-spacing:                  3rem;
        $nav-item-color:                    $color-dark-teal;
        $nav-dropdown-max-width:            15rem;
        $nav-dropdown-item-padding:         1rem;
        $nav-dropdown-border-width:         1px;
        $nav-dropdown-border-color:         $color-light-grey;
        $nav-dropdown-accent-color:         $color-green;
        $nav-dropdown-accent-thickness:     3px;
        $nav-dropdown-border-radius:        2px;

/*
*   =-= Container
*/
        $container-width:                   75rem;

/*
*   =-= Page Hero
*/
        $page-hero-breadcrumb-size:         $body-font-size-small;
        $page-hero-breadcrumb-link-color:   $color-green;
        $page-hero-breadcrumb-margin:       2rem;
        $page-hero-caption-max-width:       32rem;
        $page-hero-vertical-padding:        5rem;

/*
*   =-= Post Hero
*/
        $post-hero-padding:                 $base-section-padding * 1.5;
        $post-hero-background:              $color-dark-teal;
        $post-hero-image-opacity:           .2;
        $post-hero-title-max-width:         60rem;
        $post-hero-title-bottom-margin:     1.5rem;
        $post-hero-meta-color:              $color-grey;
        $post-hero-date-color:              $color-white;

/*
*   =-= Intro
*/
        $intro-body-width:                  55%;
        $intro-hexagon-image-width:         40%;
        $intro-block-width:                 50%;
        $intro-block-gap:                   7%;
        $intro-block-vertical-gap:          3rem;
        $intro-block-icon-size:             75px;
        $intro-block-icon-margin-bottom:    1.5rem;

/*
*   =-= Image Blocks
*/
        $img-block-background:                  $color-light-grey;
        $img-block-height:                      30%;
        $img-block-theater-theme-border:        1rem;
        $img-block-theater-theme-border-color:  $color-white;
        $img-block-theater-img-width:           92%;

/*
*   =-= Post Grid
*/
        $post-grid-block-width:             33%;
        $post-grid-padding:                 $gutter * 2;
        $post-grid-image-height:            56.25%;
        $post-grid-image-bg-color:          $color-grey;
        $post-grid-image-bottom-margin:     1.5rem;
        $post-grid-image-border-radius:     2px;
        $post-grid-category-bottom-margin:  1rem;
        $post-grid-category-color:          $color-green;
        $post-grid-category-font-size:      $body-font-size-small;
        $post-grid-title-line-height:       $line-height-small;
        $post-grid-title-bottom-margin:     1rem;
        $post-grid-caption-bottom-margin:   1.5rem;
        $post-grid-caption-color:           $color-grey;
        $post-grid-tag-spacing:             0.5rem;
        $post-grid-footer-gap:              4rem;
        $post-grid-sorry-border:            1px solid $color-light-grey;
        $post-grid-sorry-padding:           1rem;
        $post-grid-sorry-text-color:        $color-dark-teal;

/*
*   =-= The Post
*/
        $post-accent-thickness:             8px;
        $post-accent-color:                 $color-green;
        $post-content-vertical-padding:     $base-section-padding / 2;
        $post-content-bold-color:           $color-dark-teal;
        $post-content-italic-weight:        $font-weight-light;

/*
*   =-= Team
*/
        $team-member-column:                33%;
        $team-member-gap:                   4rem;
        $team-member-max-width:             20rem;
        $team-member-photo-mat:             $color-light-grey;
        $team-member-hover-fx-speed:        150ms;
        $team-member-hover-fx-easing:       linear;
        $team-member-hover-fx-scale:        1.1;
        $team-member-name-top-margin:       1.5rem;
        $team-member-role-size:             $body-font-size-small;
        $team-member-role-color:            $color-green;
        $team-member-role-top-margin:       0.5rem;

/*
*   =-= Subservice Grid
*/
        $sub-grid-item-padding:             2rem;
        $sub-grid-accent-thickness:         4px;
        $sub-grid-accent-color:             $color-green;
        $sub-grid-description-font-size:    $body-font-size-small;
        $sub-grid-item-width:               30%;
        $sub-grid-background:               $color-dark-grey;
        $sub-grid-section-padding:          $base-section-padding / 2;


/*
*   =-= Use Case Grid
*/
        $use-case-grid-header-margin:       5rem;
        $use-case-grid-footer-margin:       5rem;
        $use-case-grid-image-padding:       56.25%;
        $use-case-grid-image-margin:        2rem;
        $use-case-grid-title-color:         $color-green;
        $use-case-grid-title-margin:        1rem;
        $use-case-grid-caption-color:       $color-grey;
        $use-case-grid-caption-margin:      1rem;

/*
*   =-= CTA
*/
        $cta-background:                    $color-light-grey;
        $cta-inner-max-width:               40rem;
        $cta-text-font-weight:              $font-weight-light;
        $cta-text-line-height:              $line-height-small;


/*
*   =-= Misc.
*/
        $accent-line-width:                 3.125rem;
        $accent-line-thickness:             5px;
        $accent-line-gap:                   3rem;


/*
*   =-= Footer
*/
        $page-footer-background:            $color-dark-teal;
        $page-footer-vertical-padding:      $base-section-padding;

        $footer-group-spacing:              7.5rem;

        $footer-font-size:                  $body-font-size-small;
        $footer-link-color:                 $color-white;
        $footer-link-opacity:               .4;
        $footer-parent-link-bottom-margin:  2.125rem;
        $footer-nav-link-spacing:           1rem;

        $footer-emphasized-text-size:             $body-font-size-med;
        $footer-emphasized-text-opacity:          1;
        $footer-emphasized-text-color:            $color-white;
        $footer-emphasized-text-weight:           $font-weight-medium;
        $footer-emphasized-link-underline-width:  1px;
        $footer-emphasized-link-underline-color:  $color-green;

        $footer-header-font-size:           $footer-font-size;
        $footer-header-font-color:          $color-green;
        $footer-header-font-weight:         $font-weight-normal;
        $footer-header-line-height:         $line-height-flat;
