/*
*   Subservice Grid
*
*   Styles for the grid of subservice blocks.
*/

.sub-grid {

  background: $sub-grid-background;
  padding: $sub-grid-section-padding 0;

  .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;
  }

  .sub-grid__item {
    background: $color-white;
    width: $sub-grid-item-width;
    padding: $sub-grid-item-padding $sub-grid-item-padding $sub-grid-item-padding 0;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .sub-grid__intro {
    @extend .rich-text;
    text-align: center;
    margin-bottom: $sub-grid-section-padding / 2;

    .accent {
      margin-left: auto;
      margin-right: auto;
    }
  }

  .sub-grid__section-footer {
    text-align: center;
    margin-top: $sub-grid-section-padding / 2;
  }

  .sub-grid__icon {
    padding-left: calc(#{$sub-grid-item-padding} - #{$sub-grid-accent-thickness});
    margin-bottom: 1rem;
  }

  .sub-grid__footer {
    padding-left: calc(#{$sub-grid-item-padding} - #{$sub-grid-accent-thickness});

    a {
      font-size: $sub-grid-description-font-size;
      color: $sub-grid-accent-color;
      text-decoration: none;

      .arrow {
        margin-left: .5rem;
        transition: all 300ms ease;
      }

      &:hover .arrow {
        margin-left: 1rem;
      }
    }
  }

  .sub-grid__title {
    padding-left: calc(#{$sub-grid-item-padding} - #{$sub-grid-accent-thickness});
    border-left: $sub-grid-accent-thickness solid $sub-grid-accent-color;
    @extend .inner-heading-4;
    font-family: $font-family-text;
  }

  .sub-grid__desc {
    padding-left: $sub-grid-item-padding;
    font-size: $sub-grid-description-font-size;
  }

}
