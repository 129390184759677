/*
*   Navbar
*
*   Page navbar styles
*/

.navbar {

  height: $navbar-height;
  position: relative;
  z-index: 999;

  // Make container flex
  .container {
    display: flex;
    height: 100%;
    align-items: center;
  }

  // Logo
  .logo {
    display: block;
    img {
      height: 32px;
    }
  }
  // Nav
  .main-nav {
    flex-grow: 1;
    text-align: right;

    & > a {
      display: inline-block;
      position: relative;
      margin-right: $nav-item-spacing;
      text-decoration: none;
      color: $nav-item-color;
      padding: .75rem 0;

      img {
        display: inline-block;
        vertical-align: middle;
        margin-left: .75rem;
      }

      &:hover .dropdown  {
        display: block;
      }

    }
  }
  // Jump Button
  .jump {

  }

  // Dropdown
  .dropdown {
    position: absolute;
    top: 100%;
    width: $nav-dropdown-max-width;
    left: 0;
    text-align: left;
    border: $nav-dropdown-border-width solid $nav-dropdown-border-color;
    border-top: $nav-dropdown-accent-thickness solid $nav-dropdown-accent-color;
    display: none;
    border-radius: $nav-dropdown-border-radius;
    background: $color-white;

    & > a {
      text-decoration: none;
      display: block;
      @include padding( $nav-dropdown-item-padding $nav-dropdown-item-padding );
      color: $nav-item-color;

      &:not(:first-of-type) {
        border-top: $nav-dropdown-border-width solid $nav-dropdown-border-color;
      }
    }
  }

}
