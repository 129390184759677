/*
*   Recent Posts
*
*   Styles for the lineup of 3 recent posts.
*/

.recent-posts {

  .recent-posts__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .recent-posts__headline {
    @extend .inner-heading-1;
  }

  .recent-posts__posts {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 3rem;

    &.less-than-3 {
      justify-content: flex-start;
    }
  }

}

.recent-post {

  width: $post-grid-block-width;
  display: block;
  text-decoration: none;

  &:not(:first-child) {
    margin-left: 5%;
  }

  .recent-post__image {
    position: relative;
    padding-top: $post-grid-image-height;
    background: $post-grid-image-bg-color;
    margin-bottom: $post-grid-image-bottom-margin;
    border-radius: $post-grid-image-border-radius;

    img {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0; left: 0;
      object-fit: cover;
    }
  }

  .recent-post__category {
    color: $post-grid-category-color;
    margin-bottom: $post-grid-category-bottom-margin;
    font-size: $post-grid-category-font-size;
  }

  .recent-post__title {
    @extend .inner-heading-2;
    line-height: $post-grid-title-line-height;
    margin-bottom: $post-grid-title-bottom-margin;
  }

  .recent-post__caption {
    color: $post-grid-caption-color;
    margin-bottom: $post-grid-caption-bottom-margin;
  }

  .recent-post__tags {
    .tag {
      margin-right: $post-grid-tag-spacing;
    }
  }

}
