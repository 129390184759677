/*
*   Home Hero
*
*   Styles for the home hero block.
*/

.home-hero {

  min-height: 85vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .container {
    display: flex;
  }

  .home-hero__body {
    width: 55%;
  }

  .home-hero__scroll {
    position: absolute;
    bottom: 35vh;
    right: 41%;
    font-size: $body-font-size-small;
    color: #697179;

    div {
      display: block;
      transform: rotate(-90deg);

      transform-origin: right bottom;
      vertical-align: bottom;
      height: 15px;
      display: flex;
      align-items: center;
      line-height: 1;
      margin: 0;
      text-align: left

      span {
        display: inline;
        height: 15px;
      }

      img {
        display: inline-block;
        margin-right: 1rem;
        height: 12px;

        animation: arrow 1s linear infinite;
        animation-direction: alternate;
      }
    }
  }

  .home-hero__mat {
    position: absolute;
    top: -10vh;
    right: 0;
    height: 100vh;
    width: 40%;
    background: $color-light-grey;
    clip-path: polygon(50% 0%, 100% 38%, 95% 95%, 1% 79%, 0 28%);
    animation: mat 6s cubic-bezier(0.85, 0, 0.15, 1) infinite;
    animation-direction: alternate;
  }

  .home-hero__image {
    position: absolute;
    right: 0;
    top: 0;
    height: 85vh;
    width: 35%;

    clip-path: polygon(7% 34%, 50% 3%, 100% 20%, 100% 70%, 64% 100%, 23% 88%);
    animation: clip 6s cubic-bezier(0.85, 0, 0.15, 1) infinite;
    animation-direction: alternate;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      -webkit-user-drag: none;
      -khtml-user-drag: none;
      -moz-user-drag: none;
      -o-user-drag: none;
      user-drag: none;
    }
  }

  .home-hero__heading {
    @extend .page-heading-1;
  }

  .home-hero__footer {
    margin-top: 3rem;
  }

}

@keyframes arrow {
  0% {
    margin-right: 1rem;
  }

  100% {
    margin-right: 2rem;
  }
}

@keyframes mat {
  0% {
    clip-path: polygon(50% 0%, 100% 38%, 95% 95%, 1% 79%, 0 28%);
  }
  100% {
    clip-path: polygon(65% 5%, 100% 38%, 90% 90%, 1% 83%, 0 42%);
  }
}

@keyframes clip {
  0% {
    clip-path: polygon(7% 34%, 50% 3%, 100% 20%, 100% 70%, 64% 100%, 23% 88%);
  }
  100% {
    clip-path: polygon(11% 14%, 81% 0, 100% 23%, 100% 78%, 48% 97%, 1% 71%);
  }
}
