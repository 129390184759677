/*
*   Post
*
*   Styles for the blog post.
*/

.post {

  .container { display: flex; }

  .post__content {
    flex: 2;
    border-top: $post-accent-thickness solid $post-accent-color;
    position: relative;
    top: -$post-accent-thickness;
    @include padding( $post-content-vertical-padding null );
    margin-right: $gutter * 2;

    *:first-child {
      margin-top: 0;
    }
  }

  .post__sidebar {
    flex: 1;
    @include padding( ($base-section-padding / 2) null );
    margin-left: $gutter;
  }

  h2 { @extend .inner-heading-1; }
  h3 { @extend .inner-heading-2; }
  h4 { @extend .inner-heading-3; }
  h5 { @extend .inner-heading-4; }

  strong { color: $post-content-bold-color; }
  em { font-weight: $post-content-italic-weight; }


}
