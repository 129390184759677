/*
*   Paragraph
*
*   Global styles for the <p> element
*/

p {

  line-height: $paragraph-line-height;
  @include margin( $paragraph-margin null );
  color: $paragraph-color;
  font-family: $body-font;

  &:first-child { margin-top: 0; }
  &:last-child { margin-bottom: 0; }

}
