/*
*   Tag
*
*   Global styles for the blog post tags.
*/

.tag {

  display: inline-block;
  background: $color-green;
  border-radius: 2px;
  color: $color-white;
  font-size: $body-font-size-small;
  text-decoration: none;

  @include padding( .25rem 1rem );

}
