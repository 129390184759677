/*
*   Accent
*
*   Global accent line style.
*/

.accent {

  height: $accent-line-thickness;
  width:  $accent-line-width;
  display: block;
  @include margin( null null $accent-line-gap null );

}
