/*
*   Intro
*
*   Styling for the homepage intro section.
*/

.intro {

  position: relative;

  .container { display: flex; justify-content: flex-end; }

  .intro__body {
    width: $intro-body-width;
  }

  .intro__heading {
    @extend .inner-heading-1;
  }

  .intro__blocks {
    display: flex;
    flex-wrap: wrap;
  }

  .intro__image {

    position: absolute;
    top: 0;
    left: 0;
    width: $intro-hexagon-image-width;
    height: 100%;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      clip-path: polygon(46% 0, 100% 11%, 89% 82%, 56% 100%, 0 91%, 0 11%);

      animation: intro_image_anim 6s cubic-bezier(0.85, 0, 0.15, 1) infinite;
      animation-direction: alternate;
    }

  }

}

.intro-block {
  width: $intro-block-width - $intro-block-gap;
  margin-right: $intro-block-gap;
  margin-top: $intro-block-vertical-gap;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  .intro-block__icon {
    max-width: $intro-block-icon-size;
    margin-bottom: $intro-block-icon-margin-bottom;

    img { width: 100%; }
  }

  .intro-block__caption {
    flex-grow: 1;
    font-size: $body-font-size-small;
  }
}

@keyframes intro_image_anim {
  0% {
    clip-path: polygon(46% 0, 100% 11%, 89% 82%, 56% 100%, 0 91%, 0 11%);
  }
  100% {
    clip-path: polygon(31% 3%, 91% 24%, 81% 94%, 34% 96%, 0 81%, 0 22%);
  }
}
