/*
*   Container
*
*   The global page container common on all pages.
*/

.container {
  @include size( $container-width, auto );
  @include margin( null auto );

  @media only screen and (max-width: $container-width) {
    width: auto;
  }
}
