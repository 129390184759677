/*
*   Platform Hero
*
*   Styles for the platform hero block.
*/

.platform-hero {

  .platform-hero__heading {
    text-align: center;
    @extend .page-heading-1;
    letter-spacing: -2px;
  }

  .platform-hero__subheading {
    text-align: center;
    margin-top: 0;
  }

}
