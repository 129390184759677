/*
*   Fonts
*
*   Font file imports and font face declarations.
*/

@include font-face(
  'ILS Text',
  '../fonts/text/ILSText-Book',
  ('woff', 'woff2')
) {
  font-style: normal;
  font-weight: 400;
}

@include font-face(
  'ILS Text',
  '../fonts/text/ILSText-Light',
  ('woff', 'woff2')
) {
  font-style: normal;
  font-weight: 300;
}

@include font-face(
  'ILS Text',
  '../fonts/text/ILSText-LightItalic',
  ('woff', 'woff2')
) {
  font-style: italic;
  font-weight: 300;
}

@include font-face(
  'ILS Text',
  '../fonts/text/ILSText-Medium',
  ('woff', 'woff2')
) {
  font-style: normal;
  font-weight: 500;
}

@include font-face(
  'ILS Text',
  '../fonts/text/ILSText-BookItalic',
  ('woff', 'woff2')
) {
  font-style: italic;
  font-weight: 400;
}

@include font-face(
  'ILS Display',
  '../fonts/display/ILS-Light',
  ('woff', 'woff2')
) {
  font-style: normal;
  font-weight: 300;
}

@include font-face(
  'ILS Display',
  '../fonts/display/ILS-SemiBold',
  ('woff', 'woff2')
) {
  font-style: normal;
  font-weight: 600;
}
