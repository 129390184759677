/*
*   Page Hero
*
*   Styles for the page hero block.
*/

.page-hero {

  @include padding( $page-hero-vertical-padding null );

  // Page Heading
  h1 {
    @extend .page-heading-2;
  }

  // Caption
  .page-hero__caption {
    max-width: $page-hero-caption-max-width;
  }

  // Breadcrumb
  .breadcrumb {
    font-size: $page-hero-breadcrumb-size;
    margin-bottom: $page-hero-breadcrumb-margin;

    .breadcrumb__link a {
      color: $page-hero-breadcrumb-link-color;
    }

  }

}
